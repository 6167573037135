import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {
  CardActionArea,
  CardHeader,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { purples } from '../theme';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '500px',
  },
  category: {
    backgroundColor: purples[2],
    color: 'white',
  },
  link: {
    textDecoration: 'none',
  },
  media: {
    height: 0,
    paddingTop: '100%', // 16:9
  },
}));

const BlogCard = ({ blog }) => {
  const classes = useStyles();

  return (
    <AnchorLink to={`${blog.slug}#`} className={classes.link}>
      <Card className={classes.root} square>
        <CardActionArea>
          <CardHeader
            className={classes.category}
            title={
              <Typography variant="h4" align="center">
                {blog.category.toUpperCase()}
              </Typography>
            }
          />
          <CardMedia
            className={classes.media}
            image={blog.tile}
            title={blog.shortTitle}
          />
        </CardActionArea>
      </Card>
    </AnchorLink>
  );
};

export default BlogCard;
