import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GoogleMapReact from 'google-map-react';
import Logo from '../images/PREP_Logo_Insta_FB.png';
import { graphql, useStaticQuery } from 'gatsby';

const shopLocation = {
  address: '1/93 Burwood Rd, Hawthorn',
  lat: -37.821322,
  lng: 145.025836,
  markerSize: 40,
};

const useStyles = makeStyles((theme) => ({
  logo: {
    padding: '2px',
  },
  marker: {
    marginLeft: `-${shopLocation.markerSize / 2}px`,
    marginTop: `-${shopLocation.markerSize / 2}px`,
  },
}));

const Marker = ({ classes }) => {
  return (
    <div className={classes.marker}>
      <img
        className={classes.logo}
        src={Logo}
        width={`${shopLocation.markerSize}px`}
        height={`${shopLocation.markerSize}px`}
        alt="logo"
        title={shopLocation.address}
      />
    </div>
  );
};

const StyledMap = ({ logo }) => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query ApiQuery {
      site {
        siteMetadata {
          google {
            mapsApiKey
          }
        }
      }
    }
  `);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: data.site.siteMetadata.google.mapsApiKey }}
      defaultCenter={{
        lat: shopLocation.lat,
        lng: shopLocation.lng,
      }}
      defaultZoom={16}
    >
      <Marker classes={classes} lat={shopLocation.lat} lng={shopLocation.lng} />
    </GoogleMapReact>
  );
};

export default StyledMap;
