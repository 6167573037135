import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Button,
} from '@material-ui/core';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import PhysioTable from './PhysioTable';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '500px',
    backgroundColor: theme.palette.transparent.white1,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  link: {
    textDecoration: 'none',
    fontWeight: '600',
    color: theme.palette.primary.main,
    minWidth: '100%',
  },
}));

const PhysioCard = () => {
  const classes = useStyles();

  return (
    <Card className={classes.root} square>
      <CardHeader
        title={
          <Typography variant="h5" align="center">
            PHYSIO
          </Typography>
        }
      />
      <CardContent>
        <Typography variant="body1" gutterBottom>
          Physiotherapy is an evidence-based healthcare profession, which is
          continually challenged by the research. Physiotherapists are outcome
          focussed professionals, who can accurately identify a problem through
          a comprehensive assessment and then provide advice on strength and
          rehab programs to facilitate a goal directed outcomes.
        </Typography>

        <PhysioTable />
      </CardContent>
      <CardActions style={{ justifyContent: 'center' }} disableSpacing>
        <Button aria-label="show more" color="primary" variant="outlined">
          <AnchorLink to="/services" className={classes.link}>
            More info...
          </AnchorLink>
        </Button>
      </CardActions>
    </Card>
  );
};

export default PhysioCard;
