import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { Grid, Button, Typography, makeStyles } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import WrappedTextField from './form/wrapper/WrappedTextField';
import {
  composeValidators,
  required,
  email,
  phoneNumber,
} from './form/validators';
import WrappedRadioGroup from './form/wrapper/WrappedRadioGroup';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.transparent.white1,
  },
}));

const ContactForm = () => {
  const data = useStaticQuery(graphql`
    query FormQuery {
      site {
        siteMetadata {
          endpoints {
            contactEndpoint
          }
        }
      }
    }
  `);

  const classes = useStyles();

  const [submitResult, setSubmitResult] = useState(null);
  const [sending, setSending] = useState(false);

  const onSubmit = (values) => {
    setSending(true);
    return fetch(data.site.siteMetadata.endpoints.contactEndpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
      .then((result) =>
        setSubmitResult({
          success: true,
          result,
        })
      )
      .catch((result) =>
        setSubmitResult({
          success: false,
          result,
        })
      )
      .finally(() => setSending(false));
  };

  if (submitResult) {
    if (submitResult.success) {
      return (
        <>
          <Typography variant="body1">
            Successfully received contact request
          </Typography>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => setSubmitResult(null)}
          >
            Submit again
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Typography variant="body1">
            A problem occured submitting you request. Please try again.
          </Typography>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => setSubmitResult(null)}
          >
            Submit again
          </Button>
        </>
      );
    }
  }

  const validateMessage = (value, otherValues) => {
    return otherValues.subject === 'Other' && !value ? 'Required' : undefined;
  };

  return (
    <div className={classes.container}>
      <Form
        onSubmit={onSubmit}
        render={({ form, pristine, handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} autoComplete="off" noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  name="name"
                  component={WrappedTextField}
                  label="Your name"
                  variant="outlined"
                  fullWidth
                  required
                  validate={required}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="phoneNumber"
                  component={WrappedTextField}
                  label="Your contact number"
                  variant="outlined"
                  fullWidth
                  required
                  validate={composeValidators(required, phoneNumber)}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="email"
                  component={WrappedTextField}
                  label="Your email address"
                  variant="outlined"
                  fullWidth
                  required
                  validate={composeValidators(required, email)}
                />
              </Grid>
              <Grid item xs={12} sm={7} lg={5}>
                <Field
                  name="subject"
                  component={WrappedRadioGroup}
                  label="I'm interested in"
                  variant="outlined"
                  options={[
                    {
                      value: 'More info',
                      label: 'More info',
                    },
                    {
                      value: 'Book a physio consultation',
                      label: 'Book a physio consultation',
                    },
                    {
                      value: 'Book a performance consultation',
                      label: 'Book a performance consultation',
                    },
                    {
                      value: 'Other',
                      label: 'Other',
                    },
                  ]}
                  fullWidth
                  required
                  validate={required}
                />
              </Grid>
              <Grid item xs={12} sm={5} lg={7}>
                <Field
                  name="message"
                  component={WrappedTextField}
                  label="Message"
                  variant="outlined"
                  multiline
                  rows={3}
                  rowsMax={10}
                  fullWidth
                  required={values.subject === 'Other'}
                  validate={validateMessage}
                />
              </Grid>
              <Grid item xs={12} container spacing={1} justify="center">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    disabled={submitting || sending}
                  >
                    Send
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="default"
                    disabled={submitting || pristine}
                    onClick={form.reset}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </div>
  );
};

export default ContactForm;
