import React, { useState } from 'react';
import _ from 'lodash';
import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from 'react-time-ago';
import CloseIcon from '@material-ui/icons/Close';
import en from 'javascript-time-ago/locale/en';
import { graphql, useStaticQuery } from 'gatsby';
import {
  Grid,
  Dialog,
  makeStyles,
  Typography,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import Image from 'gatsby-image';

const useStyles = makeStyles((theme) => ({
  tile: {
    minHeight: '50px',
    minWidth: '50px',
    height: '100%',
  },
  dialogRoot: {
    margin: 0,
    padding: 0,
    '&:first-child': {
      padding: 0,
    },
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  timestamp: {
    color: theme.palette.transparent.gray,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: 1000,
  },
}));

const InstagramFeed = () => {
  const classes = useStyles();
  const [activePost, setActivePost] = useState(null);
  TimeAgo.addLocale(en);

  const data = useStaticQuery(graphql`
    query {
      allInstaNode {
        edges {
          node {
            id
            likes
            comments
            mediaType
            preview
            original
            timestamp
            caption
            localFile {
              childImageSharp {
                fluid(maxHeight: 500, maxWidth: 500, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            # Only available with the public api scraper
            thumbnails {
              src
              config_width
              config_height
            }
            dimensions {
              height
              width
            }
          }
        }
      }
    }
  `);

  const items = _.orderBy(
    data.allInstaNode.edges,
    (item) => item.node.timestamp,
    'desc'
  );
  const deleteTags = (text) => text.replace(/^(\s*#\w+\s*)+$/gm, '');

  if (activePost) {
    console.log(
      'activePost.timestamp',
      activePost.timestamp,
      new Date(activePost.timestamp * 1000)
    );
  }

  return (
    <>
      {activePost && (
        <Dialog
          onClose={() => setActivePost(null)}
          aria-labelledby="view post"
          open
          fullWidth
          maxWidth="sm"
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => setActivePost(null)}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent classes={{ root: classes.dialogRoot }}>
            {activePost.localFile && (
              <Image fluid={activePost.localFile.childImageSharp.fluid} />
            )}
            {!activePost.localFile && activePost.original && (
              <img src={activePost.original} alt="post" />
            )}
            <div className={classes.dialogContent}>
              <Typography variant="subtitle2" gutterBottom>
                {`${activePost.likes || 0} likes`}
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                className={classes.timestamp}
              >
                <ReactTimeAgo
                  date={new Date(activePost.timestamp * 1000)}
                  locale="en"
                />
              </Typography>
              {activePost.caption &&
                activePost.caption.split('\n').map((para, index) => (
                  <Typography key={index} paragraph>
                    {para}
                  </Typography>
                ))}
            </div>
          </DialogContent>
        </Dialog>
      )}
      <Grid container alignContent="stretch" alignItems="stretch">
        {items
          .filter((item) => !!item.node.localFile)
          .map((item, i) => {
            const captionText = item.node.caption
              ? deleteTags(item.node.caption)
              : 'Instagram Post';
            console.log('instra node', item.node);
            return (
              <Grid item key={i} xs={6} sm={4} md={3} lg={2}>
                <div
                  className={classes.tile}
                  role="button"
                  onKeyPress={() => setActivePost(item.node)}
                  tabIndex={0}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setActivePost(item.node)}
                >
                  {item.node.localFile && (
                    <Image
                      fluid={item.node.localFile.childImageSharp.fluid}
                      caption={captionText}
                    />
                  )}
                </div>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default InstagramFeed;
