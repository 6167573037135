import {
  InputLabel,
  FormControl,
  makeStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import * as React from 'react';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  label: {
    position: 'relative',
  },
  groupVertical: {
    marginTop: '-8px',
    marginBottom: '-8px',
  },
  optionVertical: {
    marginTop: '-8px',
    '&:first-child': {
      marginTop: 0,
    },
  },
}));

const NULL_VALUE = '';

const booleanToString = (value) => {
  if (value === true || value === 'true') {
    return 'true';
  }
  if (value === false || value === 'false') {
    return 'false';
  }
  return NULL_VALUE;
};

const stringToBoolean = (value) => value === true || value === 'true';

const WrappedRadioGroup = ({
  input: { name, onChange, value },
  meta: { touched, error, invalid },
  placeholder,
  margin,
  disabled,
  required,
  row,
  options,
  label,
  helperText,
  nullLabel,
  fullWidth,
  ...rest
}) => {
  const classes = useStyles();

  const asBoolean = _.isBoolean(options[0].value);

  const handleOnChange = (checked, optionValue) => {
    if (checked) {
      if (optionValue === NULL_VALUE) {
        onChange(null);
      } else {
        onChange(asBoolean ? stringToBoolean(optionValue) : optionValue);
      }
    }
  };

  return (
    <FormControl
      component="fieldset"
      margin={margin}
      disabled={disabled}
      error={touched && invalid}
      required={required}
      fullWidth={fullWidth}
    >
      {label && (
        <InputLabel shrink className={classes.label}>
          {label}
        </InputLabel>
      )}
      <RadioGroup
        classes={{
          root: row ? undefined : classes.groupVertical,
        }}
        aria-label={label}
        name={name}
        value={asBoolean ? booleanToString(value) : value}
        onChange={(event) =>
          handleOnChange(event.target.checked, event.target.value)
        }
        margin={margin}
        row={row}
        {...rest}
      >
        {options.map((option) => (
          <FormControlLabel
            className={row ? undefined : classes.optionVertical}
            value={asBoolean ? booleanToString(option.value) : option.value}
            key={option.value}
            control={<Radio color="default" />}
            label={option.label}
          />
        ))}
        {!required && (
          <FormControlLabel
            className={row ? undefined : classes.optionVertical}
            value={NULL_VALUE}
            control={<Radio color="default" />}
            label={nullLabel}
          />
        )}
      </RadioGroup>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
      {helperText && !(touched && error) && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

WrappedRadioGroup.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  required: PropTypes.bool,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  row: PropTypes.bool,
  helperText: PropTypes.any,
  nullLabel: PropTypes.string,
  fullWidth: PropTypes.bool,
};

WrappedRadioGroup.defaultProps = {
  required: false,
  disabled: false,
  label: null,
  margin: 'dense',
  row: false,
  helperText: '',
  nullLabel: 'N/A',
  fullWidth: false,
};

export default WrappedRadioGroup;
