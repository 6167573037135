import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  prepBoxLeft: {
    maxWidth: '180px',
    minWidth: '180px',
    minHeight: '180px',
    maxHeight: '180px',
    marginLeft: 'auto',
  },
  prepBoxRight: {
    maxWidth: '180px',
    minWidth: '180px',
    minHeight: '180px',
    maxHeight: '180px',
  },
  prepBoxLeftXs: {
    maxWidth: '160px',
    minWidth: '160px',
    minHeight: '160px',
    maxHeight: '160px',
    marginLeft: 'auto',
  },
  prepBoxRightXs: {
    maxWidth: '160px',
    minWidth: '160px',
    minHeight: '160px',
    maxHeight: '160px',
  },
}));

const PrepBox = () => {
  const classes = useStyles();
  const smallWidth = useMediaQuery('(max-device-width: 400px)');

  //  TODO fix with css 'aspect-ratio: attr(width) / attr(height);'

  return (
    <>
      <Grid container justify="center">
        <Grid xs={6} item>
          <Grid
            className={smallWidth ? classes.prepBoxLeftXs : classes.prepBoxLeft}
            style={{
              color: 'white',
              backgroundColor: '#8d42f6',
            }}
            container
            justify="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle2" align="center">
                PREPARATION
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} item>
          <Grid
            className={
              smallWidth ? classes.prepBoxRightXs : classes.prepBoxRight
            }
            style={{
              color: '#8d42f6',
              backgroundColor: 'white',
            }}
            container
            justify="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle2" align="center">
                REHABILITATION
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} item>
          <Grid
            className={smallWidth ? classes.prepBoxLeftXs : classes.prepBoxLeft}
            style={{
              color: 'black',
              backgroundColor: 'white',
            }}
            container
            justify="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle2" align="center">
                EXERCISE
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} item>
          <Grid
            className={
              smallWidth ? classes.prepBoxRightXs : classes.prepBoxRight
            }
            style={{
              color: 'white',
              backgroundColor: 'black',
            }}
            container
            justify="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle2" align="center">
                PROGRAMMING
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PrepBox;
