import React from 'react';
import BackgroundSection from '../components/BackgroundSection';
import { useStaticQuery, graphql } from 'gatsby';
import { makeStyles, Typography, Grid, Button } from '@material-ui/core';
import StyledMap from '../components/StyledMap';
import PageSection from '../components/PageSection';
import InstagramFeed from '../components/InstagramFeed';
import TopBanner from '../components/TopBanner';
import BookNowButton from '../components/BookNowButton';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';
import TopBar from '../components/TopBar';
import { purples, greys } from '../theme';
import { Parallax } from 'react-parallax';

import PhysioCard from '../components/PhysioCard';
import PrepBox from '../components/PrepBox';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import BackgroundImage from 'gatsby-background-image';
import BlogCard from '../components/BlogCard';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  mapContainer: {
    height: '50vh',
    minWidth: '100%',
  },
  heading: {
    paddingTop: theme.spacing(2),
  },
  paragraph: {
    color: 'black',
    marginBottom: '1em',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  link: {
    textDecoration: 'none',
    fontWeight: '600',
    color: theme.palette.secondary.main,
    minWidth: '100%',
  },
  whiteLink: {
    color: 'white',
  },
}));

const Index = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      mainBackground: file(relativePath: { eq: "rowing-ant.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      serviceBackground: file(relativePath: { eq: "overhead-weights.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 5192) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      contactBackground: file(
        relativePath: { eq: "one-handed-handstand.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 5192) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      meaganPortrait: file(relativePath: { eq: "meagan2.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 818) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      site {
        siteMetadata {
          contact {
            email
            phoneNumber
          }
        }
      }
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1000
      ) {
        edges {
          node {
            frontmatter {
              slug
              shortTitle
              category
              digest
              tile
            }
          }
        }
      }
    }
  `);

  const getPhoneNumber = () => data.site.siteMetadata.contact.phoneNumber;
  const getEmail = () => data.site.siteMetadata.contact.email;

  return (
    <>
      <TopBar />
      <BackgroundSection
        backgroundPosition="center center"
        backgroundImage={data.mainBackground.childImageSharp.fluid}
      >
        <TopBanner
          heading="PHYSIO + PERFORMANCE"
          texts={['Prevent injuries.', 'Move well.', 'Perform better.']}
        />
      </BackgroundSection>

      <PageSection
        id="howWeCanHelp"
        title="How we can help you"
        bgColor={greys[0]}
        labelColor="black"
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item sm={12} md={6}>
            <PrepBox />
          </Grid>
          <Grid item sm={12} md={6}>
            <Typography
              variant="body1"
              className={classes.paragraph}
              gutterBottom
            >
              PREP Physio + Performance provides a high-quality service in an
              elite space, with the knowledge to match. We will provide a 1:1
              client facing service, which will consist of movement pattern
              retraining and education as the primary treatment strategies. Our
              goal is to help you move well, so you can return to your
              activities.
            </Typography>
            <Typography
              variant="body1"
              className={classes.paragraph}
              gutterBottom
            >
              PREP Physio + Performance will use the initial consultation to
              provide a thorough assessment and prescribe appropriate and
              progressive exercises. We will also use education as a tool, to
              ensure you have a full understanding as to the management pathway,
              in order to achieve your goals.
            </Typography>
          </Grid>
        </Grid>
      </PageSection>

      <Parallax
        blur={0}
        bgImage={data.serviceBackground.childImageSharp.fluid.src}
        bgImageSrcSet={data.serviceBackground.childImageSharp.fluid.srcSet}
        bgImageSizes={data.serviceBackground.childImageSharp.fluid.sizes}
        bgImageAlt="Services"
        strength={500}
      >
        <div id="services" style={{ height: '20px' }}></div>
        <PageSection id="services-inner" title="Services" labelColor="white">
          <div style={{ height: '20px' }}></div>
          <Grid container justify="center">
            <Grid item>
              <PhysioCard />
            </Grid>
          </Grid>
          <div style={{ height: '40px' }}></div>
        </PageSection>
      </Parallax>

      <PageSection
        id="aboutMe"
        title="About me"
        bgColor={purples[3]}
        labelColor="black"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" className={classes.paragraph} gutterBottom>
              Meagan Davis
            </Typography>
            <Typography
              variant="body1"
              className={classes.paragraph}
              gutterBottom
            >
              My background as an Accredited Exercise Physiologist, coupled with
              my experience in the sub-elite setting has led to a strong
              interest in biomechanics, especially of the lower limb. I have a
              special interest in rehabilitation, specifically of the lower limb
              tendons, the hip and groin and running retraining. I place a
              strong emphasis on developing specialised strength and
              conditioning programs, which are sports specific in nature, to
              optimise injury rehabilitation and performance.
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <BackgroundImage
              fluid={data.meaganPortrait.childImageSharp.fluid}
              backgroundColor={`#040e18`}
              backgroundPosition="center top"
              style={{ minWidth: '100%' }}
            >
              <div
                style={{
                  paddingTop: '80%',
                }}
              ></div>
            </BackgroundImage>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <Button aria-label="show more" color="secondary" variant="outlined">
            <AnchorLink to="/about" className={classes.link}>
              More details
            </AnchorLink>
          </Button>
        </div>
      </PageSection>

      <PageSection
        id="blog"
        title="Blog"
        labelColor={purples[4]}
        bgColor={greys[0]}
      >
        <div style={{ height: '20px' }}></div>
        <Grid container>
          {data.allMarkdownRemark.edges.map(({ node: { frontmatter } }) => (
            <Grid key={frontmatter.slug} item xs={12} sm={6} md={4}>
              <BlogCard blog={frontmatter} />
            </Grid>
          ))}
        </Grid>
        <div style={{ height: '40px' }}></div>
      </PageSection>

      <Parallax
        blur={0}
        bgImage={data.contactBackground.childImageSharp.fluid.src}
        bgImageSrcSet={data.contactBackground.childImageSharp.fluid.srcSet}
        bgImageSizes={data.contactBackground.childImageSharp.fluid.sizes}
        bgImageAlt="Contact"
        strength={500}
      >
        <div id="contact" style={{ height: '20px' }}></div>
        <PageSection id="contact-inner" title="Get in touch" labelColor="white">
          <Typography
            variant="body1"
            style={{ color: 'white' }}
            align="center"
            gutterBottom
          >
            We'd love to hear from you via our contact form below.
            <br />
            Alternatively you can email us at{' '}
            <a
              style={{ color: 'white' }}
              href={`mailto:${getEmail()}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getEmail()}
            </a>{' '}
            or call us on{' '}
            <a
              style={{ color: 'white' }}
              href={`tel:${getPhoneNumber()}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getPhoneNumber()}
            </a>
            . Ready to book? If so, feel free to complete a{' '}
            <AnchorLink className={classes.whiteLink} to="/book#booking">
              booking request
            </AnchorLink>
            .
          </Typography>
          <div>
            <ContactForm />
          </div>
          <div style={{ height: '40px' }}></div>
        </PageSection>
      </Parallax>

      <PageSection
        id="location"
        title="Where we are"
        bgColor={purples[3]}
        labelColor="black"
      >
        <div className={classes.mapContainer}>
          <StyledMap />
        </div>
        <br />
        <Typography
          variant="h4"
          className={classes.paragraph}
          align="center"
          gutterBottom
        >
          Getting here
        </Typography>
        <Typography
          variant="body1"
          style={{ color: 'black' }}
          align="center"
          gutterBottom
        >
          <span role="img" aria-label="car">
            🚗
          </span>{' '}
          1hour parking is available directly out the front of the clinic on
          Burwood Road. Alternatively, 2hour parking is available directly
          across the road at the IGA.
        </Typography>
        <Typography
          variant="body1"
          style={{ color: 'black' }}
          align="center"
          gutterBottom
        >
          <span role="img" aria-label="train">
            🚆
          </span>{' '}
          Hawthorn Train Station is a 1minute walk from the clinic.
        </Typography>
        <Typography
          variant="body1"
          style={{ color: 'black' }}
          align="center"
          gutterBottom
        >
          <span role="img" aria-label="tram">
            🚊
          </span>{' '}
          The 75 tram goes directly past the clinic on Burwood road.
        </Typography>
      </PageSection>

      <PageSection
        id="feed"
        title="What we're up to"
        bgColor={greys[0]}
        labelColor="black"
      >
        <InstagramFeed />
      </PageSection>

      <BookNowButton />
      <Footer />
    </>
  );
};

export default Index;
